import React from 'react'


function ThemeToggle(){

  return (
    <div className="toggle-button">
        <div className="toggle"></div>
    </div>
  )
}

export default ThemeToggle